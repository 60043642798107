<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col class="text-start" cols="12">
      <v-btn icon @click="to ? $router.push(to) :$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KurccBackButton',
  props: {
    to: {
      required: false
    }
  }
}
</script>
